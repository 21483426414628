@use 'variables';

.recommendations-block {
    margin: 75px 0 60px 0;
    position: relative;
    width: 100%;
    h2 {
        margin: 0 0 20px 0;
        // margin-bottom: 60px; desktop
        // text-align: center desktop
        text-align: left;
        font-size: 1.875rem;
        @include variables.bold-font;
        @include variables.for-phone-only {
            font-size: 1.875rem;
            line-height: 2.5rem;
        }
    }

    .products-slick {
        margin: 0 -10px 0 -10px;
        .product-block {
            display: block;
            padding: 0 10px;

            .image {
                position: relative;
            }
        }
    }
    .slick-slider {
        .slick-arrow {
            background: #d5d5d5;
            border-radius: 5px;
            display: flex !important;
            height: 70px;
            justify-content: center;
            z-index: 10;
            top: 36%;
            width: 30px;
            &.slick-prev {
                left: 0px;
            }
            &.slick-next {
                right: 0px;
            }
            &:before {
                content: '';
            }
            img {
                width: 10px;
            }
        }
        .awesome-dots {
            margin: 0;
            padding-top: 40px;
            text-align: left;
            width: 100%;
            li {
                display: inline-block;
                margin: 0 7.5px;
                position: relative;
                cursor: pointer;
                span {
                    background: #e2e2e2;
                    border-radius: 2px;
                    display: block;
                    height: 4px;
                    width: 30px;
                }
                &.slick-active {
                    span {
                        background: #000000;
                    }
                }
            }
        }

        .component-product {
            &__info {
                &__left {
                    @include variables.for-large-desktop-up {
                        flex: 0 0 60%;
                    }
                }
                &__right {
                    @include variables.for-large-desktop-up {
                        display: flex;
                        justify-content: flex-end;
                        flex: 0 0 40%;
                        margin-top: 2px;
                    }
                    .price {
                        display: flex;
                        align-items: baseline;
                        @include variables.for-large-desktop-up {
                            flex-direction: column;
                        }
                        .has-discount {
                            margin-right: 5px;
                        }
                        &__label {
                            display: block;
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
    }
}
