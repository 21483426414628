@use 'variables';

.availability {
    overflow: auto;

    @include variables.for-tablet-portrait-up {
        min-width: 550px;
    }

    .header {
        background: #EFEFEF;
        position: sticky;
        top: 0;
        z-index: 9999;
        padding: 8px;
        text-align: center;
        height: auto;
        width: 60px;
        @include variables.bold-font;

        &.sizes {
            width: 150px;
            left: 0;
            position: sticky;
            z-index: 10000;
        }
    }

    table {
        width: 100%;

        td {
            border-bottom: none !important;
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            padding: 8px 16px;
            text-align: center;
            span {
                background-color: #00A053;
                border-radius: 50%;
                display: inline-block;
                height: 8px;
                width: 8px;
            }
        }

        .city td {
            color: #00A053;
            font-size: 0.75rem;
            padding: 16px 16px 8px 8px;
            text-align: left;
            left: 0;
            position: sticky;
            z-index: 9998;
            @include variables.bold-font;
        }

        .shop-title {
            text-align: left;
            border: 1px solid #E2E2E2 !important;
            left: 0;
            position: sticky;
            z-index: 9998;
            background: #EFEFEF;

            div {
                width: 100%;
            }
        }

        .variation {
            border: 1px solid #E2E2E2 !important;
            width: 60px;
        }
    }
}
