@use 'variables';

.component-technologyItem {
    transition: all 0.1s;

    &.open {
        .component-technologyItem__header {
            background-color: #000;
            color: #fff;
            border-radius: 4px 4px 0px 0px;
            border: 1px solid #000;
            &::before {
                transform: rotate(225deg);
                top: 22px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
            }
        }
    }

    &:hover {
        .component-technologyItem__header {
            background-color: #000;
            color: #fff;
            transition: all 0.1s;
        }
    }

    &__header {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        min-height: 50px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        color: #000;
        cursor: pointer;
        position: relative;
            &::before {
                position: absolute;
                content: '';
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                top: 18px;
                right: 30px;
                transform: rotate(45deg);
            }
    }
    &__content {
        box-sizing: border-box;
        padding: 20px 20px 20px 20px;
        border: 1px solid #e2e2e2;
        border-top: none;
        border-radius: 0px 0px 4px 4px;
        margin-top: -12px;
        text-align: left;
    }
}
