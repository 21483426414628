@use 'variables';

.product {
    max-width: 560px;
    margin: 0 auto;

    @include variables.for-tablet-landscape-up {
        max-width: none;
    }

    &.loading {
        background-image: url('./../../../images/loader.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 200px;
        height: 500px;
        background-color: #f8f8f8;
        margin: 15px 0;
        border-radius: 10px;
    }

    &__item {
        @include variables.for-tablet-landscape-up {
            display: flex;
            flex-wrap: wrap;
            max-width: 1420px;
            margin: 30px auto;
            position: relative;

            &.isBike {
                max-width: 1220px;

                .product__content {
                    width: 59%;
                }
            }
        }
    }

    &__image {
        position: relative;

        .image {
            position: relative;
        }

        &__favourite {
            .component-favourite {
                position: absolute;
                padding: 0;

                .component-favourite__button {
                    border: none;
                }

                @include variables.for-tablet-landscape-up {
                    display: none;
                }
            }
        }

        @include variables.for-tablet-landscape-up {
            flex: 0 0 60%;
            padding-right: 40px;
        }

        @include variables.for-desktop-up {
            flex: 0 0 65%;
        }
    }

    &__touch {
        @include variables.for-tablet-landscape-up {
            display: none;
        }

        &__title {
            font-size: 25px;
            line-height: 35px;
        }

        &__category {
            color: #9d9d9d;
            font-size: 13px;
        }

        &__discounts {
            position: relative;
            margin: 20px 0px;

            .price {
                .has-discount {

                    .sale,
                    .campaign_sale,
                    .client,
                    .client_extra {
                        font-size: 20px;
                    }

                    .default {
                        font-size: 14px;
                    }
                }

                &__label {
                    display: block;
                }
            }

            .component-discount {
                &__container {
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                }

                &__new {
                    margin-right: 5px;
                    display: block;
                    padding: 8px;
                    font-size: 14px;
                }

                &__percentage {
                    padding: 8px;
                    font-size: 14px;
                    max-width: 50px;
                }
            }
        }
    }

    &__info {
        padding-bottom: 15px;
        border-bottom: 1px solid #e2e2e2;
        position: relative;

        @include variables.for-tablet-landscape-up {
            flex: 0 0 40%;
            border-top: none;
            border-bottom: none;
            padding-top: 0px;
        }

        @include variables.for-desktop-up {
            flex: 0 0 35%;
        }

        &__discounts {
            display: none;

            .component-discount {
                position: relative;

                &__new {
                    margin-right: 5px;
                    display: block;
                    padding: 8px;
                    font-size: 14px;
                }

                &__percentage {
                    padding: 8px;
                    font-size: 14px;
                    max-width: 50px;
                }

                &__container {
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 15px 0px;

                    @include variables.for-tablet-landscape-up {
                        padding: 0px 0px 15px 0px;
                    }
                }
            }
        }

        &__title {
            display: none;
            font-size: 25px;
            line-height: 35px;
        }

        &__category {
            display: none;
            color: #9d9d9d;
            font-size: 13px;
        }

        &__price {
            display: none;
            margin: 20px 0px;

            .price {
                .has-discount {

                    .sale,
                    .campaign_sale,
                    .client,
                    .client_extra {
                        font-size: 1.5rem;
                    }

                    .default {
                        font-size: 1rem;
                    }
                }

                &__label {
                    display: block;

                    p {
                        font-size: 0.9rem;
                        margin-top: 0;
                        @include variables.regular-font;
                    }
                }
            }
        }

        @include variables.for-tablet-landscape-up {

            &__discounts,
            &__title,
            &__category,
            &__price {
                display: block;
            }
        }

        &__sizes {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            margin-bottom: 10px;

            &__header {
                display: flex;
                align-items: center;
                flex: 0 0 100%;
                margin-bottom: 10px;

                h5 {
                    margin-right: 20px;
                }
            }

            .size-variation {
                align-items: center;
                border-radius: 4px;
                border: 2px solid #eeeeee;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                font-size: 16px;
                padding: 10px 15px;
                justify-content: center;
                margin-bottom: 10px;
                margin-right: 10px;
                transition: ease 0.1s;

                &.active {
                    background: #000000;
                    border: 2px solid #000000;
                    color: #ffffff;
                }

                &:hover {
                    background: #000000;
                    border: 2px solid #000000;
                    color: #ffffff;
                    transition: ease 0.1s;
                }
            }
        }

        &__buttons {
            &__header {
                display: flex;
                margin-top: 20px;

                .component-favourite {
                    display: none;
                    position: relative;
                    padding: 0;

                    @include variables.for-tablet-landscape-up {
                        display: block;
                    }
                }

                .add-to-cart {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #00a053;
                    border-radius: 4px;
                    color: #ffffff;
                    padding: 17px 15px;
                    flex: 1 1 auto;
                    cursor: pointer;
                    @include variables.bold-font;

                    &.active {
                        background: #00a053;
                        color: #ffffff;
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }

            &__footer {
                &__availability {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    flex: 0 0 100%;
                    border: 1px solid #e2e2e2;
                    padding: 17px 15px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all 0.1s;
                    @include variables.bold-font;

                    &:hover {
                        background-color: #00a053;
                        color: #fff;
                        transition: all 0.1s;
                    }
                }
            }
        }
    }

    &__content {
        margin-top: 50px;

        @include variables.for-tablet-landscape-up {
            flex: 0 0 60%;
            padding-right: 40px;
            margin-top: 80px;
        }

        @include variables.for-desktop-up {
            flex: 0 0 65%;
        }
    }

    &__introduction {
        strong {
            @include variables.bold-font;
        }

        p {
            font-size: 1rem;
            line-height: 1.688rem;
        }

        ul {
            margin-top: 10px;

            li {
                font-size: 1rem;
                list-style: none;
                line-height: 1.688rem;
                margin-bottom: 0.625rem;
                margin-left: 10px;

                &::before {
                    background-color: #000000;
                    border-radius: 2px;
                    border: 2px solid black;
                    content: '';
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: middle;
                    width: 20px;
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.688rem;
                display: inline-flex;
            }
        }
    }

    &__description {
        strong {
            @include variables.bold-font;
        }

        ul {
            margin-top: 20px;
            margin-bottom: 20px;


            li {
                font-size: 1rem;
                list-style: none;
                line-height: 1.688rem;
                margin-bottom: 0.625rem;
                margin-left: 10px;


                &::before {
                    background-color: #000000;
                    border-radius: 2px;
                    border: 2px solid black;
                    content: '';
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: middle;
                    width: 20px;

                }
            }

            p {
                font-size: 16px;
                line-height: 27px;
                display: inline-flex;
            }
        }

        p {
            font-size: 16px;
            line-height: 27px;
        }

        a {
            color: #00A053;
            text-decoration: underline;
            margin-left: 5px;
            margin-right: 2px;
        }
    }

    &__technologies {
        margin: 60px 0px;

        p {
            font-size: 1rem;
            line-height: 1.688rem;
            margin: 10px 0px;
        }

        h6 {
            font-size: 1rem;
            line-height: 1.688rem;
            @include variables.bold-font;
        }

        .technology {
            margin-bottom: 15px;
        }
    }

    &__specifications {
        display: flex;
        flex-wrap: wrap;
        margin: 60px 0px;

        &-item {
            display: flex;
            flex: 0 0 100%;
            padding: 15px 15px;
            background-color: #f5f5f5;

            &:nth-child(even) {
                background-color: #fff;
            }

            p {
                display: flex;
                font-size: 0.875rem;
                line-height: 2.4rem;
                flex: 0 0 50%;

                &:first-child {
                    padding-right: 15px;
                    @include variables.bold-font;
                }
            }
        }
    }

    &__touch-discounts {
        display: flex;
        justify-content: flex-end;
        flex: 1 1 auto;
        margin-bottom: 10px;

        .component-discount {
            position: relative;
            width: auto;
            padding: 0px;

            &__container {
                padding: 0px;
            }
        }

        @include variables.for-tablet-landscape-up {
            display: none;
        }
    }
}